import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2cfb517a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "consultation" }
const _hoisted_2 = ["src", "allow"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("iframe", {
      class: "consultation__iframe",
      src: _ctx.decodedId,
      allow: 
        'camera ' +
        _ctx.allowedIframeDataSources +
        ';microphone ' +
        _ctx.allowedIframeDataSources
      
    }, null, 8 /* PROPS */, _hoisted_2)
  ]))
}