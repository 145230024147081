
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { TextToBase64 } from "@hd2/common/src/utils";
import { useStore } from "../store";

export const ConsultationComponent = defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const { t } = useI18n();
    const decodedId: string = TextToBase64.decrypt(props.id);
    const allowedIframeDataSources = store.state.runtimeConfig
      .allowedIframeDataSources
      ? store.state.runtimeConfig.allowedIframeDataSources?.join(" ")
      : "";
    return {
      t,
      decodedId,
      allowedIframeDataSources,
    };
  },
});
export default ConsultationComponent;
